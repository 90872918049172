<template>
  <portal
    :to="target"
    :disabled="!portal"
  >
    <transition
      name="fade"
      @enter="revealModal"
    >
      <div
        v-if="isVisible"
        class="overlay overflow-y-auto top-0 left-0 w-full h-full sm:p-4 flex justify-items-center items-center"
        :class="{
          'overlay inset-0': portal,
          'inset-0': !portal,
          'fixed': !absolute || fullScreen,
          'absolute': absolute && !fullScreen,
          [OVERLAY_COLORS[overlayColor]]: true,
          [modalOverlayClass]: !!modalOverlayClass,
        }"
        :style="`z-index: ${zIndex};`"
      >
        <click-outside @click="closeModalOnOutsideClick">
          <transition name="slide">
            <div
              v-if="isContentVisible"
              :class="modalClasses"
              class="modal-content bg-ds-default w-full mx-auto h-full sm:h-auto sm:rounded-lg m-auto"
              data-test="modal-content"
            >
              <slot
                name="header"
                :close-modal="closeModal"
              >
                <button
                  v-if="isWithCloseButton"
                  class="top-0 right-0 rtl:right-auto rtl:left-0  absolute z-10 p-4 text-gray-400"
                  type="button"
                  data-test="button-close"
                  @click="closeModal"
                >
                  <svg-sprite-icon
                    :icon="require('assets/icons/close.svg')"
                  />
                </button>
              </slot>
              <slot :close-modal="closeModal" />
            </div>
          </transition>
        </click-outside>
      </div>
    </transition>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue'
import SvgSpriteIcon from 'common/components/SvgSpriteIcon'
import ClickOutside from 'common/components/ClickOutside'

const MODAL_SIZES = {
  xxs: 'sm:max-w-sm',
  xs: 'sm:max-w-md',
  sm: 'sm:max-w-lg',
  base: 'sm:max-w-3xl',
  lg: 'sm:max-w-6xl',
}

const OVERLAY_COLORS = {
  gray: 'bg-transparent-dark dark:bg-transparent-gray',
  transparent: 'bg-transparent',
}

export default {
  name: 'BModal',
  components: {
    SvgSpriteIcon,
    ClickOutside,
    Portal,
  },
  props: {
    isWithCloseButton: {
      type: Boolean,
      default: true,
    },
    isClosingOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    isScrollable: {
      type: Boolean,
      default: true,
    },
    portal: {
      type: Boolean,
      default: true,
    },
    target: {
      type: String,
      default: 'modal',
    },
    modalClass: {
      type: String,
      default: '',
    },
    modalOverlayClass: {
      type: String,
      default: '',
    },
    overlayColor: {
      type: String,
      default: 'gray',
      validator: value => Object.keys(OVERLAY_COLORS).includes(value),
    },
    size: {
      type: String,
      default: 'base',
      validator: value => Object.keys(MODAL_SIZES).includes(value),
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 999999
    },
  },
  data: () => ({
    isVisible: false,
    isContentVisible: true,
    OVERLAY_COLORS,
  }),
  computed: {
    modalOverlayClasses() {
      return {
        [OVERLAY_COLORS[this.overlayColor]]: true,
        [this.modalOverlayClass]: !!this.modalOverlayClass,
      }
    },
    modalClasses() {
      return {
        [MODAL_SIZES[this.size]]: true,
        'shadow-lg': this.overlayColor === 'transparent',
        'overflow-y-auto': this.isScrollable,
        'max-h-none': !this.isScrollable,
        relative: this.isWithCloseButton,
        [this.modalClass]: !!this.modalClass,
      }
    },
  },
  async mounted() {
    if (this.portal) {
      document.body.classList.add('overflow-hidden')
    }

    // it is need, because we need `transition` first to run modal component animation
    await this.$nextTick()

    this.isVisible = true
  },
  beforeDestroy() {
    if (this.portal) {
      document.body.classList.remove('overflow-hidden')
    }
  },
  methods: {
    revealModal() {
      this.isContentVisible = true

      this.$emit('open')
    },
    async closeModal() {
      this.isContentVisible = false

      await this.$nextTick()

      this.$emit('close')
    },
    closeModalOnOutsideClick() {
      if (this.isClosingOnOutsideClick) {
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped>
.slide-enter-active {
  animation: slide 0.3s;
}

.slide-leave-to {
  animation: slide 0.3s reverse;
}

@keyframes slide {
  from {
    transform: translateY(75px);
    opacity: 0;
  }
}

.fade-enter-active {
  transition: opacity 0.2s ease;
}

.fade-leave-to {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
